<template>
  <div id="mdl_adress" class="modal_adress">
    <div class="content_modal_adresss">
      <button class="close__modal" @click="$emit('close-popup')"></button>
      <h3 class="title__adress">
        {{ $t("add_address") }}
      </h3>
      <div :class="['group_input__modal', { invalid: errors.category }]">
        <label class="group__input_title">
          {{ $t("address.category") }}
        </label>
        <div class="select_block">
          <div class="title__select">
            <span id="content_title">
              {{ $t("address.categories." + form.category) }}
            </span>
            <div class="option_list">
              <div
                v-for="c in categories"
                :key="c"
                class="item_option__list"
                @click="form.category = c"
              >
                {{ $t("address.categories." + c) }}
              </div>
            </div>
          </div>
        </div>
        <span v-if="errors.category" class="invalid_text">
          {{ errors.category[0] }}
        </span>
      </div>
      <div :class="['group_input__modal', { invalid: errors.street }]">
        <label class="group__input_title">
          {{ $t("address.street") }}
        </label>
        <AddressMap @update="setAutocompleteData">
          <span v-if="errors.street" class="invalid_text">
            {{ errors.street[0] }}
          </span>
        </AddressMap>
      </div>
      <div :class="['group_input__modal', { invalid: errors.name }]">
        <label class="group__input_title">
          {{ $t("address.name_on_card") }}
        </label>
        <input
          type="text"
          class="style_input__modal"
          v-model="form.name"
          :placeholder="$t('address.name')"
        />
        <span v-if="errors.name" class="invalid_text">
          {{ errors.name[0] }}
        </span>
      </div>
      <div :class="['group_input__modal', { invalid: errors.house }]">
        <label class="group__input_title">
          {{ $t("address.house") }}
        </label>
        <input
          type="text"
          class="style_input__modal"
          v-model="form.house"
          :placeholder="$t('address.name')"
        />
        <span v-if="errors.house" class="invalid_text">
          {{ errors.house[0] }}
        </span>
      </div>
      <div class="doble_input">
        <div :class="['group_input__modal', { invalid: errors.floor }]">
          <label class="group__input_title">
            {{ $t("address.floor") }}
          </label>
          <input
            class="style_input__modal"
            placeholder="10"
            type="text"
            v-model="form.floor"
          />
          <span v-if="errors.floor" class="invalid_text">
            {{ errors.floor[0] }}
          </span>
        </div>
        <div :class="['group_input__modal', { invalid: errors.apartment }]">
          <label class="group__input_title">
            {{ $t("address.apartment") }}
          </label>
          <input
            type="text"
            v-model="form.apartment"
            placeholder="57B"
            class="style_input__modal"
          />
          <span v-if="errors.apartment" class="invalid_text">
            {{ errors.apartment[0] }}
          </span>
        </div>
      </div>
      <div :class="['group_input__modal', { invalid: errors.comment }]">
        <label class="group__input_title">
          {{ $t("address.additional_info") }}
        </label>
        <textarea v-model="form.comment" class="adress_textarea"></textarea>
        <span v-if="errors.comment" class="invalid_text">
          {{ errors.comment[0] }}
        </span>
      </div>
      <div class="btn_adress">
        <button id="btn-pocket" class="nav__btn active" @click="save">
          {{ $t("buttons.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AddressMap from "./AddressMap";

export default {
  components: {
    AddressMap
  },

  data() {
    return {
      errors: {},
      categories: ["home", "office", "friends"],
      form: {
        category: "home",
        name: "",
        house: "",
        floor: "",
        apartment: "",
        comment: ""
      }
    };
  },

  methods: {
    ...mapActions("auth", ["me"]),
    save() {
      this.errors = [];

      this.$http
        .post("/api/user/addresses", this.form)
        .then(async () => {
          await this.me();
          this.$emit("select-last-address");
          this.$emit("close-popup");
        })
        .catch(error => (this.errors = error.response.data.errors));
    },
    setAutocompleteData(data) {
      this.form = Object.assign({}, this.form, data);
    },
    getClass(field) {
      return ["input_cart", { error: field !== undefined }];
    }
  }
};
</script>

<style scoped>
.input_cart .input__form {
  width: 100%;
}

.address__info .input_cart input {
  width: 130px;
}
</style>
