<template>
  <div>
    <div id="please_wait_overlay" class="overlay" v-if="isLoading">
      <div class="overlay-content">
        <img
          src="/img/wait.gif"
          alt="Loading"
          class="loading-animation"
        /><br />
        <span>{{ $t("please_wait") }}</span>
      </div>
    </div>

    <div v-if="showError" id="modal_sorry" class="modal_home">
      <div class="content_modal_home">
        <button class="close__modal" @click="showError = false"></button>
        <template v-for="(error, field) in errors">
          <div class="warring_text" :key="field">
            <span>{{ error }}</span>
          </div>
        </template>
        <div class="btn_block__modal mb-0">
          <button class="nav__btn" @click="showError = false">
            OK
          </button>
        </div>
      </div>
    </div>
    <div v-if="isAddressErrorOpened" id="modal__sorry" class="modal_home">
      <div class="content_modal_home">
        <button
          class="close__modal"
          @click="isAddressErrorOpened = false"
        ></button>
        <div class="warring_text">
          <span>{{ $t("attention") }}</span
          ><br />
          {{ $t("cart.address_not_selected") }}
        </div>
        <div class="btn_block__modal mb-0">
          <button class="nav__btn" @click="isAddressErrorOpened = false">
            OK
          </button>
        </div>
      </div>
    </div>
    <section class="shopcart">
      <div class="container">
        <h1 class="shopcart__title">{{ $t("cart.title") }}</h1>
        <div class="shopcart__content">
          <div class="shopcart__order">
            <!-- Dish -->
            <div v-for="item in cart.items" :key="item.id" class="order__item">
              <img
                :src="'/storage/' + item.dish.image_menu"
                alt="logo-pizza"
                class="order__logo"
              />
              <div class="order__desc">
                <div class="order__title">
                  <span>{{ item.dish.restaurant }}</span>
                </div>
                <span class="order__name">
                  {{ item.dish.name }} {{ item.dish.price_sell }}€
                </span>
                <span
                  v-for="addition in item.additions"
                  :key="addition.id"
                  class="order__taste"
                >
                  {{ addition.name }}
                  <template v-if="addition.price_sell > 0">
                    + {{ addition.price_sell }}€
                  </template>
                </span>
                <span class="order__text">{{ item.comment }}</span>
                <div id="counter-1" class="counter">
                  <button
                    class="arr-down"
                    @click="
                      changeItemQuantity({ id: item.id, increment: false })
                    "
                  >
                    &lt;
                  </button>

                  <input
                    :value="item.quantity"
                    class="counter__num"
                    type="text"
                  />

                  <button
                    class="arr-up"
                    @click="changeItemQuantity({ id: item.id })"
                  >
                    &gt;
                  </button>
                </div>
                <div class="error_text" v-if="item.quantity == item.dish.stock">
                  {{ $t("cart.max_amount") }} : {{ item.dish.stock }}
                </div>
                <button
                  class="order__btn-trash"
                  @click="removeItem(item.id)"
                ></button>
              </div>
            </div>
            <div class="order__condiments">
              <input
                id="sp"
                v-model="form.has_cutlery"
                class="shopcart__check"
                type="checkbox"
              />
              <label for="sp">{{ $t("cart.cutlery") }}</label>
            </div>
            <div v-if="cart.coupon === null" class="order__coupon">
              <span class="order__tips-text">{{ $t("cart.coupon") }}</span>
              <div class="vidget__body-2">
                <input
                  v-model="coupon"
                  :class="['coupon_input', { error: couponError }]"
                  type="text"
                />
                <button class="coupon_apply_button" @click="applyCoupon">
                  {{ $t("apply") }}
                </button>
              </div>
            </div>
            <div class="order__tips">
              <span class="order__tips-text">{{ $t("cart.tips") }}</span>
              <div class="vidget__body-2">
                <span
                  :class="['order__tips-item', { active: form.tips === 0.05 }]"
                  @click="setTipsServer(0.05)"
                >
                  5%
                </span>
                <span
                  :class="['order__tips-item', { active: form.tips === 0.07 }]"
                  @click="setTipsServer(0.07)"
                >
                  7%
                </span>
                <span
                  :class="['order__tips-item', { active: form.tips === 0.1 }]"
                  @click="setTipsServer(0.1)"
                >
                  10%
                </span>
                <span
                  :class="['order__tips-item', { active: form.tips === 0.15 }]"
                  @click="setTipsServer(0.15)"
                >
                  15%
                </span>
                <span
                  :class="['order__tips-item', { active: form.tips === 0 }]"
                  @click="setTipsServer(0)"
                  >{{ $t("cart.no_tips") }}
                </span>
              </div>
            </div>
            <div class="info__buy">
              <div class="info__buy-item">
                {{ $t("cart.subtotal") }}: <span>{{ cart.subtotal }}</span>
              </div>
              <div class="info__buy-item">
                {{ $t("cart.tips") }}:
                <span>{{ Math.round(form.tips * 100) }}%</span>
              </div>
              <div v-if="!isPickupMode" class="info__buy-item">
                {{ $t("cart.delivery_fee") }}:
                <span>{{ cart.delivery_fee }}€</span>
              </div>
              <div v-if="cart.coupon !== null" class="info__buy-item">
                {{ $t("cart.coupon") }}:
                <span>
                  {{ cart.coupon.code }}
                  <template v-if="cart.coupon.amount !== null">
                    {{ cart.coupon.amount
                    }}<template
                      v-if="
                        cart.coupon.type == 'discount_e' ||
                          cart.coupon.type == 'welcome_discount_e'
                      "
                      >&euro;</template
                    >
                    <template v-else>%</template>
                  </template>
                </span>
              </div>
              <div v-if="isAmex" class="info__buy-item">
                {{ $t("cart.amex_fee") }}: <span>3%</span>
              </div>
              <div v-if="isPickup" class="info__buy-item">
                {{ $t("cart.pickup_discount") }}:
                <span>{{ Math.round(cart.pickup_discount * 100) }}%</span>
              </div>
              <div v-if="cart.is_balance" class="info__buy-item">
                <span v-if="cart.balance >= 0"
                  >{{ $t("cart.use_delovery_balance") }}
                </span>
                <span v-else>{{ $t("cart.pay_off_delovery_balance") }} </span>:
                <span v-if="cart.balance > 0">
                  - {{ parseFloat(cart.balance).toFixed(2) }}</span
                >
                <span v-else>
                  + {{ parseFloat(-cart.balance).toFixed(2) }}</span
                >
              </div>
            </div>
            <div class="info__total">
              <div class="info__total-item">
                {{ $t("cart.total") }}: <span>{{ total }}€</span>
              </div>
            </div>
            <button class="order__btn" @click="createOrder">
              {{ $t("cart.place_my_order") }}
            </button>
          </div>
          <div class="shopcart__nav">
            <div class="shopcart__nav-order">
              <span class="nav-text">{{ $t("cart.how_to_order") }}</span>
              <button
                id="btn-pocket"
                v-if="cart.is_pickup_available"
                :class="['nav__btn', { active: isPickupMode }]"
                @click="isPickupMode = true"
              >
                <svg
                  viewBox="0 0 20.78 29.467"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Сгруппировать_597"
                    data-name="Сгруппировать 597"
                    transform="translate(-1.248)"
                  >
                    <path
                      id="Контур_176"
                      d="M11.64,0A5.652,5.652,0,0,0,6,5.641V8.088H7.84V5.641a3.8,3.8,0,1,1,7.6,0V8.088h1.841V5.641A5.649,5.649,0,0,0,11.64,0Zm5.641,8.088v2.7a.921.921,0,1,1-1.841,0v-2.7H7.84v2.7a.921.921,0,1,1-1.841,0v-2.7H2.076a.924.924,0,0,0-.828.919V28.541a.923.923,0,0,0,.926.926H21.11a.926.926,0,0,0,.919-.926V9.007a.927.927,0,0,0-.919-.919Z"
                      data-name="Контур 176"
                    />
                  </g>
                </svg>
                {{ $t("pickup_on_site") }}
              </button>
              <button
                id="btn-bicycle"
                :class="['nav__btn', { active: !isPickupMode }]"
                @click="isPickupMode = false"
              >
                <svg
                  viewBox="0 0 43.397 30.145"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Сгруппировать_599"
                    data-name="Сгруппировать 599"
                    transform="translate(-354 -418.931)"
                  >
                    <g
                      id="Сгруппировать_598"
                      data-name="Сгруппировать 598"
                      transform="translate(354 418.931)"
                    >
                      <g id="Сгруппировать_598-2" data-name="Сгруппировать 598">
                        <path
                          id="Контур_177"
                          d="M17.524,42.09a1.86,1.86,0,1,0,1.9,1.86A1.891,1.891,0,0,0,17.524,42.09Z"
                          data-name="Контур 177"
                          transform="translate(-7.703 -19.625)"
                        />
                        <path
                          id="Контур_178"
                          d="M1.506,11.738H.561a.511.511,0,0,0-.511.511v2.044a.511.511,0,0,0,.511.511h5.4c-3.925,2.361-4.349,6.94-4.308,9.091a1.2,1.2,0,0,0,1.216,1.16H3.985a5.912,5.912,0,0,0,11.82,0H29.091a5.912,5.912,0,0,0,11.82,0h1.313a1.231,1.231,0,0,0,.91-.378,1.145,1.145,0,0,0,.307-.915C42.745,16.423,36.286,11.84,32.1,11.538a.8.8,0,0,1-.644-.4L30.241,8.8a3.9,3.9,0,0,0,.746.092,2.827,2.827,0,0,0,1.165-.23.541.541,0,0,0,.322-.511V4.011a.542.542,0,0,0-.317-.511,3.639,3.639,0,0,0-4.088,1.15l-.133-.261a1.149,1.149,0,0,0-1.288-.577l-2.719.6a.828.828,0,0,0-.659.807.674.674,0,0,0,.68.669h2.361a.619.619,0,0,1,.588.4c.623,1.758,2.984,9.081.767,15.33a.634.634,0,0,1-.588.4H18.349a.96.96,0,0,1-.731-.347C15.4,18.815,17.481,16.3,19.4,14.8h2.167a.511.511,0,0,0,.511-.511V12.243a.511.511,0,0,0-.511-.511H20.9M9.872,28.678a3.7,3.7,0,1,1,3.771-3.7A3.7,3.7,0,0,1,9.872,28.678Zm25.142,0a3.769,3.769,0,1,1,.005,0Z"
                          data-name="Контур 178"
                          transform="translate(-0.05 -0.648)"
                        />
                        <path
                          id="Контур_179"
                          d="M66.734,42.09a1.86,1.86,0,1,0,1.9,1.86A1.88,1.88,0,0,0,66.734,42.09Z"
                          data-name="Контур 179"
                          transform="translate(-31.766 -19.625)"
                        />
                        <path
                          id="Контур_180"
                          d="M22.07,10.514H3.382S3.494,3.13,12.648,3.13,22.07,10.514,22.07,10.514Z"
                          data-name="Контур 180"
                          transform="translate(-1.679 -0.574)"
                        />
                        <path
                          id="Контур_181"
                          d="M21.052-1.871a.749.749,0,0,0-.673.6.748.748,0,0,0-.672-.6c-.3,0-.663.14-.672.723C19.008-.284,20.379.685,20.379.685s1.372-.968,1.345-1.833C21.715-1.732,21.354-1.873,21.052-1.871Z"
                          data-name="Контур 181"
                          transform="translate(-9.333 1.871)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                {{ $t("delivery_title") }}
              </button>
            </div>
            <div class="shopcart__nav-delivery">
              <span id="delivery-text" class="nav-text">
                {{ $t("delivery_time") }}
              </span>
              <div class="delivery-data">
                <span class="day-text">{{ deliveryDay }}</span>
                <span class="day-time">{{ deliveryTime }}</span>
                <button
                  id="btn-date1"
                  class="nav__btn"
                  @click="toggleSelectDelivery"
                >
                  {{ $t("cart.select_date_and_hour") }}
                </button>
              </div>
            </div>
            <!-- Address -->
            <div v-show="!isPickupMode" class="shopcart__nav-address">
              <span id="text-address" class="nav-text">
                {{ $t("delivery_address") }}
              </span>
              <div class="vidget__body">
                <label
                  v-for="address in user.addresses"
                  :key="address.id"
                  class="radio"
                >
                  <input
                    :checked="form.address_id === address.id"
                    class="radio__real"
                    name="term"
                    type="radio"
                    @click="form.address_id = address.id"
                  />
                  <span class="radio__fake"></span>
                  <div class="radio__address">
                    <span class="address__text">
                      {{
                        ["home", "office", "friends"].includes(address.category)
                          ? $t("address.categories." + address.category)
                          : address.category
                      }}
                    </span>
                    <span class="address__text2">{{ address.street }}</span>
                  </div>
                </label>
              </div>
              <button id="btn-date2" class="nav__btn" @click="toggleAddAddress">
                {{ $t("add_address") }}
              </button>
            </div>
            <!-- Credit card -->
            <div class="shopcart__nav-pay">
              <span id="text-payement" class="nav-text">
                {{ $t("cart.payment_method") }}
              </span>
              <span class="text-covid">{{ $t("cart.cash") }}</span>
              <div class="vidget__body">
                <label
                  v-for="card in user.credit_cards"
                  :key="card.id"
                  class="radio_2"
                  @click="setPaymentInfo('cc', card.id)"
                >
                  <input
                    class="radio__real_2"
                    name="cc"
                    type="radio"
                    :checked="form.cc_id === card.id"
                  />
                  <span class="radio__fake_2"></span>
                  <div class="radio__address">
                    <span class="address__text">{{ $t("credit_card") }}</span>
                    <span class="address__text2">
                      {{ card.pan }}
                    </span>
                  </div>
                </label>
                <label class="radio_2" @click="setPaymentInfo('cc')">
                  <input
                    class="radio__real_2"
                    name="cc"
                    type="radio"
                    :checked="user.credit_cards.length === 0"
                  />
                  <span class="radio__fake_2"></span>
                  <div class="radio__address">
                    <span class="address__text">{{
                      $t("new_credit_card")
                    }}</span>
                    <span class="address__text2"></span>
                  </div>
                </label>
                <div
                  class="balance-use"
                  @click.prevent="isUseBalance()"
                  v-bind:class="{ disabled: user.balance <= 0 }"
                >
                  <input
                    class="checbox__modal_style"
                    name="balance"
                    id="balance"
                    type="checkbox"
                    :checked="cart.is_balance"
                  />
                  <label v-if="cart.balance >= 0" for="balance">
                    {{ $t("cart.use_delovery_balance") }} {{ user.balance }}
                  </label>
                  <label v-else for="balance">
                    {{ $t("cart.pay_off_delovery_balance") }} {{ user.balance }}
                  </label>
                </div>
              </div>
              <div class="btn_mobail__shopcart">
                <button class="order__btn" @click="createOrder">
                  {{ $t("cart.place_my_order") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <DeliveryPopup
      v-show="isSelectDeliveryOpened"
      ref="delivery_popup"
      @close-popup="toggleSelectDelivery"
      @set-deliver-from="form.deliver_from = $event"
      @set-deliver-to="handleSetDeliverTo"
      @set-period="form.period = $event"
      @set-is-tomorrow="form.is_tomorrow = $event"
    />
    <AddressPopup
      v-if="isAddAddressOpened"
      @select-last-address="selectLastAddress"
      @close-popup="toggleAddAddress"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { formatInterval } from "@/helpers";
import DeliveryPopup from "@/components/DeliveryPopup";
import AddressPopup from "@/components/AddressPopup";

export default {
  components: {
    DeliveryPopup,
    AddressPopup
  },

  data() {
    return {
      extraShopCategories: [],
      isAddressErrorOpened: false,
      isAddAddressOpened: false,
      isSelectDeliveryOpened: false,
      isTomorrowOnlyAlertOpened: false,
      activeCategorySlug: null,
      isLoading: false,
      coupon: "",
      couponError: false,
      form: {
        deliver_from: null,
        is_tomorrow: null,
        deliver_to: null,
        period: null,
        address_id: null,
        payment_type: "cc",
        cc_id: null,
        tips: 0.07,
        has_cutlery: false
      },
      isAmex: false,
      errors: [],
      showError: false,
      swiperOptions: {
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },

  computed: {
    ...mapState("cart", ["cart", "is_extra_shop_enabled"]),
    ...mapState("auth", ["user"]),
    ...mapGetters("cart", ["itemsCount", "isPickup"]),
    isPickupMode: {
      get() {
        return this.isPickup;
      },
      async set(value) {
        await this.setIsPickup(value);
        this.$refs.delivery_popup.prepare();
      }
    },
    categoriesSwiper() {
      return this.$refs.categoriesSwiper.$swiper;
    },
    isAddressFilled() {
      if (this.isPickup) {
        return true;
      }

      return this.form.address_id != null;
    },
    total() {
      let amount = this.cart.total + this.cart.total * this.form.tips;

      if (this.isAmex) {
        amount += amount * 0.03;
      }
      if (this.isPickup) {
        amount -= amount * this.cart.pickup_discount;
      }
      if (this.cart.is_balance)
        if (amount > this.user.balance) amount = amount - this.user.balance;
        else amount = 0;

      return Math.round((amount + Number.EPSILON) * 100) / 100;
    },
    isFormValid() {
      return (
        this.form.deliver_from != null &&
        this.form.deliver_to != null &&
        this.isAddressFilled
      );
    },
    deliveryDay() {
      if (this.form.is_tomorrow == null) {
        return "—";
      }

      return this.form.is_tomorrow
        ? this.$t("delivery.tomorrow")
        : this.$t("delivery.today");
    },
    deliveryTime() {
      return this.form.deliver_from == null || this.form.deliver_to == null
        ? "—"
        : formatInterval(this.form.deliver_from, this.form.deliver_to);
    }
  },

  created() {
    if (this.is_extra_shop_enabled) {
      this.getExtraShopCategories();
    }
  },

  mounted() {
    document.body.classList.remove("page");
    this.setDefaultAddress();
    this.setDefaultCard();
    this.setTipsServer(this.form.tips);

    if (this.user.balance < 0) {
      this.setUseBalance({ balance: this.user.balance, is_bal: 1 });
    }

    this.isTomorrowOnlyAlertOpened =
      this.cart.delivery_intervals.today.find(
        interval => interval.available
      ) === undefined && this.cart.delivery_intervals.tomorrow.length > 0;
  },

  destroyed() {
    document.body.classList.add("page");
  },

  methods: {
    ...mapActions("cart", [
      "getCart",
      "removeItem",
      "changeItemQuantity",
      "setIsPickup",
      "setDeliverTo",
      "setUseBalance",
      "setTips"
    ]),
    getExtraShopCategories() {
      this.$http.get("/api/extra-shop/categories").then(response => {
        this.extraShopCategories = response.data.data;
        if (this.extraShopCategories.length > 0) {
          this.activeCategorySlug = this.extraShopCategories[0].slug;
        }
      });
    },
    toggleAddAddress() {
      if (this.isAddAddressOpened) {
        this.setDefaultAddress();
      }

      this.isAddAddressOpened = !this.isAddAddressOpened;
    },
    toggleSelectDelivery() {
      this.isSelectDeliveryOpened = !this.isSelectDeliveryOpened;
    },
    setDefaultAddress() {
      if (this.user.addresses.length > 0 && this.form.address_id === null) {
        this.form.address_id = this.user.addresses[0].id;
      }
    },
    setDefaultCard() {
      if (this.user.credit_cards.length > 0 && this.form.cc_id === null) {
        this.form.cc_id = this.user.credit_cards[0].id;
      } else {
        delete this.form.cc_id;
        this.isAmex = false;
      }
    },
    isUseBalance() {
      this.cart.is_balance = !this.cart.is_balance;
      let balance = 0;
      if (this.cart.is_balance) {
        let amount = this.cart.total + this.cart.total * this.form.tips;

        if (this.isAmex) {
          amount += amount * 0.03;
        }
        if (this.isPickup) {
          amount -= amount * this.cart.pickup_discount;
        }
        if (this.cart.is_balance)
          if (amount < this.user.balance) balance = amount;
          else balance = this.user.balance;

        this.setUseBalance({ balance: balance, is_bal: 1 });
      } else {
        this.setUseBalance({ balance: 0, is_bal: 0 });
      }
    },
    setTipsServer(tips) {
      this.form.tips = tips;
      this.setTips({ tips: tips });
    },
    setPaymentInfo(type, cc_id) {
      this.form.payment_type = type;
      if (cc_id === undefined) {
        delete this.form.cc_id;
        this.isAmex = false;
      } else {
        this.form.cc_id = cc_id;

        const card = this.user.credit_cards.find(
          card => card.id === this.form.cc_id
        );
        this.isAmex = card && [34, 37].includes(parseInt(card.pan.slice(0, 2)));
      }
    },
    selectLastAddress() {
      if (this.user.addresses.length > 0) {
        this.form.address_id = this.user.addresses[0].id;
      }
    },
    createOrder() {
      if (!this.isAddressFilled && !this.isPickupMode) {
        this.isAddressErrorOpened = true;
      }
      if (!this.isFormValid) return;
      if (this.isLoading) return;

      this.isLoading = true;
      this.$http
        .post("/api/user/orders", this.form)
        .then(async response => {
          this.showError = false;
          const order = response.data.data;

          await this.getCart();
          this.isLoading = false;
          if (order.status !== "payment") {
            this.$cookies.set("lock_mode", false, Infinity);
          }

          await this.$router.push({
            name: order.status === "payment" ? "order.payment" : "order",
            params: { order: order.id }
          });
        })
        .catch(error => {
          this.showError = true;
          this.errors = error.response.data.errors;
          this.isLoading = false;
        });
    },
    applyCoupon() {
      this.couponError = false;
      this.$http
        .post("/api/cart/apply-coupon", { coupon: this.coupon })
        .then(() => this.getCart())
        .catch(() => (this.couponError = true));
    },
    setActiveCategory(category, index) {
      this.activeCategorySlug = category.slug;
      this.categoriesSwiper.slideTo(index);
      this.categoriesSwiper.updateSlidesClasses();
    },
    handleSetDeliverTo(deliver_to) {
      this.form.deliver_to = deliver_to;
      this.setDeliverTo(deliver_to);
    }
  },

  metaInfo() {
    return {
      title: this.$t("cart.title")
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~swiper/css/swiper.css";

select {
  width: 25%;
  background-image: linear-gradient(45deg, transparent 50%, #5ebcab 50%),
    linear-gradient(135deg, #5ebcab 50%, transparent 50%);
  background-position: calc(100% - 12px) calc(0.55em + 2px),
    calc(100% - 7px) calc(0.55em + 2px);
  background-size: 5px 5px, 5px 5px, 0 1.5em;
  background-repeat: no-repeat;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  cursor: pointer;
  z-index: 10;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 18px;
}

.loading-animation {
  width: 80px;
  height: auto;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .loading-animation {
    width: 50px;
  }
}
</style>
