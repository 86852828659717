<template>
  <div>
    <div v-if="showError" id="modal_sorry" class="modal_home">
      <div class="content_modal_home">
        <button class="close__modal" @click="showError = false"></button>
        <div class="warring_text" :key="field">
          <span>{{ $t("address_outside") }}</span>
        </div>
        <div class="btn_block__modal mb-0">
          <button class="nav__btn" @click="showError = false">
            OK
          </button>
        </div>
      </div>
    </div>
    <GmapAutocomplete
      class="style_input__modal"
      :types="['address']"
      :fields="['address_components', 'geometry']"
      :bounds="bounds"
      :options="{ strictBounds: true }"
      @place_changed="setPosition"
      placeholder="5 av. Princesse Grace"
      :value="addressInput"
      v-if="bounds"
    />
    <slot></slot>

    <GmapMap
      :center="center || { lat: 0, lng: 0 }"
      :options="mapOptions"
      :zoom="zoom"
      ref="map"
      style="height: 200px; margin-top: 20px"
    >
      <GmapMarker v-if="position" :position="position" />
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from "gmap-vue";
import { mapBounds } from "@/helpers";

export default {
  data() {
    return {
      mapOptions: {
        disableDefaultUI: true,
        draggable: false,
        gestureHandling: "none"
      },
      position: null,
      center: null,
      bounds: null,
      zoom: 1,
      addressInput: '',
      showError: false
    };
  },

  computed: {
    google: gmapApi
  },

  mounted() {
    this.$refs.map.$mapPromise.then(map => {
      this.bounds = mapBounds(this.google);
      map.fitBounds(this.bounds);
    });
  },

  methods: {
    setPosition(place) {
      if (place.name === "") {
        this.$emit("update", "");
        this.position = null;
        this.zoom = 1;
        this.$refs.map.$mapPromise.then(map => {
          map.fitBounds(this.bounds);
        });
        return;
      }
      this.showError = false;
      this.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      const curPosition = new window.google.maps.LatLng(
        this.position.lat,
        this.position.lng
      );

      const polygon = new window.google.maps.Polygon({
        paths: [
          {lat: 43.7154107, lng: 7.3867607},
          {lat: 43.7191327, lng: 7.4182995},
          {lat: 43.7562480, lng: 7.4563694},
          {lat: 43.7606882, lng: 7.4528825},
          {lat: 43.7586425, lng: 7.4459195},
          {lat: 43.7559845, lng: 7.4448681},
          {lat: 43.7555195, lng: 7.4383664},
          {lat: 43.7273205, lng: 7.3918707},
          {lat: 43.7154107, lng: 7.3867607}
        ]
      });

      const is_in_poly = window.google.maps.geometry.poly.containsLocation(
        curPosition,
        polygon
      );
      if (!is_in_poly) {
        this.showError = true;
        this.addressInput = '';
        return false;
      }

      const components = place.address_components;
      const street_number = this.byType(components, "street_number");
      const street = this.byType(components, "route");
      const city = this.byType(components, "locality");
      const full_street = `${street_number} ${street}`.trim();
      this.addressInput = full_street;
      this.center = this.position;
      this.zoom = 15;

      this.$emit("update", {
        street: `${full_street}, ${city}`,
        ...this.position
      });
    },
    byType(components, type) {
      const component = components.find(component =>
        component.types.some(t => t === type)
      );

      return component === undefined ? "" : component.short_name;
    }
  }
};
</script>

<style>
.pac-container {
  z-index: 10000;
}
</style>

<style scoped>
.input_cart .input__form {
  width: 100%;
}
</style>
